<template>
<!--  v-show="!isSkeletonLoading.leagueStanding" -->
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("LEAGUE_STANDINGS") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        v-for="(res, idx) in results"
        :key="idx"
        @click="selectTeam(idx)"
        :class="{ 'tab-selected': idx === teamSelected }"
      >
        {{ res.name }}
      </button>
    </div>

    <div class="matchList-content_wrapper">
      <!-- Mobile -->
      <template v-if="isMobile">
        <table class="matchList-content_table table-full" v-if="mobileResults">
          <thead>
            <tr>
              <th
                class="matchList-content_table-title"
                :colspan="headerFirstHalfLabel.length"
              >
                {{ mobileResults.name }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="label in headerFirstHalfLabel" :key="label">
                {{ $t(label) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="mobileResults.standings.length !== 0">
              <tr
                v-for="(match, index) in splitHalf(
                  mobileResults.standings,
                  'first'
                )"
                :key="index"
              >
                <td>{{ $t(positionLabel[index]) }}</td>
                <td>{{ match.totalCount }}</td>
                <td>{{ match.winCount }}</td>
                <td>{{ match.drawCount }}</td>
                <td>{{ match.loseCount }}</td>
                <td>{{ match.getScore }}</td>
                <td>{{ match.loseScore }}</td>
                <td>{{ match.goalDifference }}</td>
                <td>{{ match.integral }}</td>
                <td>{{ match.ranking }}</td>
                <td>{{ match.winRate + "%" }}</td>
              </tr>
              <tr>
                <td
                  class="matchList-content_separator"
                  v-for="label in headersecondHalfLabel"
                  :key="label"
                >
                  {{ $t(label) }}
                </td>
              </tr>
              <tr
                v-for="(match, index) in splitHalf(
                  mobileResults.standings,
                  'second'
                )"
                :key="index"
              >
                <td>{{ $t(positionLabel[index]) }}</td>
                <td>{{ match.totalCount }}</td>
                <td>{{ match.winCount }}</td>
                <td>{{ match.drawCount }}</td>
                <td>{{ match.loseCount }}</td>
                <td>{{ match.getScore }}</td>
                <td>{{ match.loseScore }}</td>
                <td>{{ match.goalDifference }}</td>
                <td>{{ match.integral }}</td>
                <td>{{ match.ranking }}</td>
                <td>{{ match.winRate + "%" }}</td>
              </tr>
            </template>
            <template v-else>
              <td :colspan="headerFirstHalfLabel.length">
                {{ $t("NO_DATA") }}
              </td>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="headerFirstHalfLabel.length"></td>
            </tr>
          </tfoot>
        </table>
      </template>

      <!-- Desktop -->
      <template v-else>
        <table
          class="matchList-content_table table-half"
          v-for="(res, idx) in results"
          :key="idx"
          :class="{ 'team-selected': isMobile && idx === teamSelected }"
        >
          <thead>
            <tr>
              <th
                class="matchList-content_table-title"
                :colspan="headerFirstHalfLabel.length"
              >
                {{ res.name }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="label in headerFirstHalfLabel" :key="label">
                {{ $t(label) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="res.standings.length !== 0">
              <tr
                v-for="(match, index) in splitHalf(res.standings, 'first')"
                :key="index"
              >
                <td>{{ $t(positionLabel[index]) }}</td>
                <td>{{ match.totalCount }}</td>
                <td>{{ match.winCount }}</td>
                <td>{{ match.drawCount }}</td>
                <td>{{ match.loseCount }}</td>
                <td>{{ match.getScore }}</td>
                <td>{{ match.loseScore }}</td>
                <td>{{ match.goalDifference }}</td>
                <td>{{ match.integral }}</td>
                <td>{{ match.ranking }}</td>
                <td>{{ match.winRate + "%" }}</td>
              </tr>
              <tr>
                <td
                  class="matchList-content_separator"
                  v-for="label in headersecondHalfLabel"
                  :key="label"
                >
                  {{ $t(label) }}
                </td>
              </tr>
              <tr
                v-for="(match, index) in splitHalf(res.standings, 'second')"
                :key="index"
              >
                <td>{{ $t(positionLabel[index]) }}</td>
                <td>{{ match.totalCount }}</td>
                <td>{{ match.winCount }}</td>
                <td>{{ match.drawCount }}</td>
                <td>{{ match.loseCount }}</td>
                <td>{{ match.getScore }}</td>
                <td>{{ match.loseScore }}</td>
                <td>{{ match.goalDifference }}</td>
                <td>{{ match.integral }}</td>
                <td>{{ match.ranking }}</td>
                <td>{{ match.winRate + "%" }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="headerFirstHalfLabel.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>
  </section>

  <!-- <Loading v-show="isLoading"></Loading>
  <div  v-show="isShowNoData && !isLoading">
    {{ $t("NO_DATA") }}
  </div> -->
  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.leagueStanding"></GeneralContentSkeleton> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';
export default {
  components: {
    GeneralContentSkeleton,
  },
  data() {
    return {
      positionLabel: ["TOTAL", "HOME", "AWAY", "LAST_6"],
      headerFirstHalfLabel: [
        "SHORT_FULL_TIME",
        "SHORT_MATCH",
        "SHORT_WIN",
        "SHORT_DRAW",
        "SHORT_LOSE",
        "SHORT_GOALS",
        "SHORT_CEDE",
        "SHORT_MARGIN",
        "SHORT_POINTS",
        "SHORT_RANKING",
        "SHORT_WIN_RATE",
      ],
      headersecondHalfLabel: [
        "SHORT_HALF_TIME",
        "SHORT_MATCH",
        "SHORT_WIN",
        "SHORT_DRAW",
        "SHORT_LOSE",
        "SHORT_GOALS",
        "SHORT_CEDE",
        "SHORT_MARGIN",
        "SHORT_POINTS",
        "SHORT_RANKING",
        "SHORT_WIN_RATE",
      ],
      teamSelected: "homeTeam",
      // isLoading: false,
      isNodata: false,
      results: null,
      mobileResults: null,
      matchId: "",
      isSkeletonLoading: {
        leagueStanding: false,
      }
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
    // this.matchId = 2175176;
  },
  mounted() {
    this.getLeagueStandingData();
  },
  watch: {
    teamSelected(newVal) {
      this.mobileResults = this.results[newVal];
    },
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        this.getLeagueStandingData();
      },
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.getLeagueStandingData();
        }
      },
    },
  },

  computed: {
    ...mapGetters([
      "isMobile", 
      "currentLocale",
      "manualRefreshMatchDetail",
    ]),
  },
  methods: {
    ...mapActions(["getLeagueStanding"]),

    async getLeagueStandingData() {
      // this.isLoading = true;
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.leagueStanding = true;
      }
      const result = await this.getLeagueStanding({ matchId: this.matchId });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.leagueStanding = false;
        this.verifyIfAllAPICallsDone();
      }
      // this.isLoading = false;

      this.dataValidation(result.result);
    },
    dataValidation(data) {
      if (!data.homeTeam && !data.awayTeam) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
        this.results = data;
        this.mobileResults = this.results[this.teamSelected];
      }
    },
    splitHalf(arr, arrPos) {
      const firstHalf = arr.slice(0, Math.ceil(arr.length / 2));
      const secondHalf = arr.slice(-Math.ceil(arr.length / 2));
      if (arrPos === "first") {
        return firstHalf;
      } else if (arrPos === "second") {
        return secondHalf;
      }
    },
    selectTeam(id) {
      this.teamSelected = id;
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.leagueStanding) {
        this.$emit("apiCallsDone");
      }
    },
  },
};
</script>

<style scoped>
.matchList-content_table {
  font-size: 0.625rem;
  line-height: 0.75rem;
  table-layout: fixed;
}

.matchList-content_table th,
.matchList-content_table td {
  border: unset;
  padding: 0.375rem 0;
}
tr {
  border-top: 0.5px solid #4b616c;
  border-bottom: 0.5px solid #4b616c;
}

@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.25rem 0;
  }
  tr {
    border-top: 0.75px solid #4b616c;
    border-bottom: 0.75px solid #4b616c;
  }
}
</style>
