<template>
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("DISTRIBUTION") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        @click="selectTeam(homeTeam.team)"
        :class="{ 'tab-selected': homeTeam.team === teamSelected }"
      >
        {{ homeTeam.name }}
      </button>
      <button
        @click="selectTeam(awayTeam.team)"
        :class="{ 'tab-selected': awayTeam.team === teamSelected }"
      >
        {{ awayTeam.name }}
      </button>
    </div>

    <div class="matchList-content_wrapper">
      <!-- Mobile -->
      <template v-if="isMobile">
        <table class="matchList-content_table table-full">
          <thead>
            <tr>
              <th
                :colspan="positionLabel.length + 1"
                class="matchList-content_table-title"
              >
                {{
                  teamSelected === "homeTeam" ? homeTeam.name : awayTeam.name
                }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th></th>
              <th v-for="l in positionLabel" :key="l">{{ $t(l) }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="teamData">
              <tr v-for="(o, index) in toMobileArray(teamData)" :key="index">
                <td class="matchList-content_separator">
                  {{
                    index === 5 || index === 6
                      ? $t(goalScoreTableAttr[index])
                      : goalScoreTableAttr[index]
                  }}
                </td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="positionLabel.length + 1">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="positionLabel.length + 1"></td>
            </tr>
          </tfoot></table
      ></template>

      <!-- Desktop -->
      <template v-else>
        <table class="matchList-content_table table-half">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th></th>
              <th v-for="(attr, i) in goalScoreTableAttr" :key="attr">
                {{ i === 5 || i === 6 ? $t(attr) : attr }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="homeTotalGoalScore">
              <tr v-for="(o, index) in homeTotalGoalScore" :key="index">
                <td>{{ $t(positionLabel[index]) }}</td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="goalScoreTableAttr.length + 1">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <table class="matchList-content_table table-half">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th></th>
              <th v-for="(attr, i) in goalScoreTableAttr" :key="attr">
                {{ i === 5 || i === 6 ? $t(attr) : attr }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="awayTotalGoalScore">
              <tr v-for="(o, index) in awayTotalGoalScore" :key="index">
                <td>{{ $t(positionLabel[index]) }}</td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="goalScoreTableAttr.length + 1">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["homeTotalGoalScore", "awayTotalGoalScore", "homeTeam", "awayTeam"],
  data() {
    return {
      positionLabel: ["TOTAL", "HOME", "AWAY"],
      goalScoreTableAttr: ["0", "1", "2", "3", "4+", "FIRST_HALF", "LAST_HALF"],
      teamSelected: this.homeTeam.team,
      teamData: this.homeTotalGoalScore,
    };
  },
  watch: {
    homeTeam(newVal) {
      this.teamSelected = newVal.team;
    },
    homeTotalGoalScore(newVal) {
      this.teamData = newVal;
    },
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  methods: {
    selectTeam(id) {
      this.teamSelected = id;
      id === "homeTeam"
        ? (this.teamData = this.homeTotalGoalScore)
        : (this.teamData = this.awayTotalGoalScore);
    },
    toMobileArray(arr) {
      return eval(arr).reduce(
        (r, a, i) => (a.forEach((b, j) => ((r[j] = r[j] || [])[i] = b)), r),
        []
      );
    },
  },
};
</script>

<style scoped>
.matchList-content_table {
  table-layout: fixed;
}

/* .matchList-content_table-subtitle th:nth-child(1) {
  width: 2rem;
} */
.matchList-content_table-subtitle th:nth-child(2),
.matchList-content_table-subtitle th:nth-child(3) {
  width: unset;
}

@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.25rem 0;
  }
}
</style>
