<template>
<!--  v-show="!isSkeletonLoading.currentSeasonStat" -->
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("STATISTICS_THIS_SEASON") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        @click="selectTeam(homeTeam.team)"
        :class="{ 'tab-selected': homeTeam.team === teamSelected }"
      >
        {{ homeTeam.name }}
      </button>
      <button
        @click="selectTeam(awayTeam.team)"
        :class="{ 'tab-selected': awayTeam.team === teamSelected }"
      >
        {{ awayTeam.name }}
      </button>
    </div>

    <template v-if="isMobile">
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-full">
          <tbody>
            <tr>
              <td class="matchList-content_separator" :colspan="9">
                {{ $t(teamSelected === "homeTeam" ? "HOME" : "AWAY") }}
                {{ $t("ACHIEVEMENT") }}
              </td>
            </tr>
            <template v-if="result">
              <tr v-for="(o, i) in objectOrderTotal" :key="o">
                <td v-if="i === 0" :rowspan="3" :colspan="1">{{ $t("TOTAL") }}</td>
                <td v-if="i === 3" :rowspan="3" :colspan="1">{{ $t("HOME") }}</td>
                <td :colspan="2">{{ $t(totalLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width: Number(teamData[`${o}Rate`]).toFixed() + '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">
                  {{
                    `${Number(teamData[o + "Rate"]).toFixed()}% [${Number(
                      teamData[o]
                    )} ${$t("SHORT_MATCH")}]`
                  }}
                </td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="9">
                  {{
                    teamSelected === "homeTeam"
                      ? $t("HOME_GOAL_STAT")
                      : $t("AWAY_GOAL_STAT")
                  }}
                </td>
              </tr>
              <tr v-for="(o, i) in objectOrderStanding" :key="o">
                <td v-if="i === 0" :rowspan="4" :colspan="1">{{ $t("TOTAL") }}</td>
                <td v-if="i === 4" :rowspan="4" :colspan="1">{{ $t("HOME") }}</td>
                <td v-if="i === 8" :rowspan="4" :colspan="1">{{ $t("LAST_6") }}</td>
                <td :colspan="2">{{ $t(standingLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width:
                          i === 2 ||
                          i === 3 ||
                          i === 6 ||
                          i === 7 ||
                          i === 10 ||
                          i === 11
                            ? (Number(teamData[o]).toFixed() / 5) * 100 + '%'
                            : Number(teamData[o]).toFixed() + '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">{{ Number(teamData[o]) }}</td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="9">
                  {{
                    `${$t(teamSelected === "homeTeam" ? "HOME" : "AWAY")} ${$t(
                      "W"
                    )} ${$t("GOALS")}`
                  }}
                </td>
              </tr>
              <tr v-for="(o, i) in objectOrderWinGoal" :key="o">
                <td :colspan="3">{{ $t(winGoalLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width: Number(teamData[`${o}Rate`]).toFixed() + '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">
                  {{
                    `${Number(teamData[o + "Rate"]).toFixed()}% [${Number(
                      teamData[o]
                    )}${$t("SHORT_MATCH")}]`
                  }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="9"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>

    <template v-else>
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-half">
          <tbody>
            <tr>
              <td
                class="matchList-content_separator matchList-content_table-title"
                :colspan="9"
              >
                {{ $t("HOME") }} {{ $t("ACHIEVEMENT") }}
              </td>
            </tr>
            <template v-if="result">
              <tr v-for="(o, i) in objectOrderTotal" :key="o">
                <td v-if="i === 0" :rowspan="3" :colspan="1">
                  {{ $t("TOTAL") }}
                </td>
                <td v-if="i === 3" :rowspan="3" :colspan="1">
                  {{ $t("HOME") }}
                </td>
                <td :colspan="2">{{ $t(totalLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width:
                          Number(result.homeTeamStat[`${o}Rate`]).toFixed() +
                          '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">
                  {{
                    `${Number(
                      result.homeTeamStat[o + "Rate"]
                    ).toFixed()}% [${Number(result.homeTeamStat[o])}${$t(
                      "SHORT_MATCH"
                    )}]`
                  }}
                </td>
              </tr>
              <tr>
                <td
                  class="matchList-content_separator matchList-content_table-title"
                  :colspan="9"
                >
                  {{ $t("HOME_GOAL_STAT") }}
                </td>
              </tr>
              <tr v-for="(o, i) in objectOrderStanding" :key="o">
                <td v-if="i === 0" :rowspan="4" :colspan="1">
                  {{ $t("TOTAL") }}
                </td>
                <td v-if="i === 4" :rowspan="4" :colspan="1">
                  {{ $t("HOME") }}
                </td>
                <td v-if="i === 8" :rowspan="4" :colspan="1">
                  {{ $t("LAST_6") }}
                </td>
                <td :colspan="2">{{ $t(standingLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width:
                          i === 2 ||
                          i === 3 ||
                          i === 6 ||
                          i === 7 ||
                          i === 10 ||
                          i === 11
                            ? (Number(result.homeTeamStat[o]).toFixed() / 5) *
                                100 +
                              '%'
                            : Number(result.homeTeamStat[o]).toFixed() + '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">{{ Number(result.homeTeamStat[o]) }}</td>
              </tr>
              <tr>
                <td
                  class="matchList-content_separator matchList-content_table-title"
                  :colspan="9"
                >
                  {{ `${$t("HOME")} ${$t("W")} ${$t("GOALS")}` }}
                </td>
              </tr>
              <tr v-for="(o, i) in objectOrderWinGoal" :key="o">
                <td :colspan="3">{{ $t(winGoalLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width:
                          Number(result.homeTeamStat[`${o}Rate`]).toFixed() +
                          '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">
                  {{
                    `${Number(
                      result.homeTeamStat[o + "Rate"]
                    ).toFixed()}% [${Number(result.homeTeamStat[o])}${$t(
                      "SHORT_MATCH"
                    )}]`
                  }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <table class="matchList-content_table table-half">
          <tbody>
            <tr>
              <td
                class="matchList-content_separator matchList-content_table-title"
                :colspan="9"
              >
                {{ $t("AWAY") }} {{ $t("ACHIEVEMENT") }}
              </td>
            </tr>
            <template v-if="result">
              <tr v-for="(o, i) in objectOrderTotal" :key="o">
                <td v-if="i === 0" :rowspan="3" :colspan="1">
                  {{ $t("TOTAL") }}
                </td>
                <td v-if="i === 3" :rowspan="3" :colspan="1">
                  {{ $t("AWAY") }}
                </td>
                <td :colspan="2">{{ $t(totalLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width:
                          Number(result.awayTeamStat[`${o}Rate`]).toFixed() +
                          '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">
                  {{
                    `${Number(
                      result.awayTeamStat[o + "Rate"]
                    ).toFixed()}% [${Number(result.awayTeamStat[o])}${$t(
                      "SHORT_MATCH"
                    )}]`
                  }}
                </td>
              </tr>
              <tr>
                <td
                  class="matchList-content_separator matchList-content_table-title"
                  :colspan="9"
                >
                  {{ $t("AWAY_GOAL_STAT") }}
                </td>
              </tr>
              <tr v-for="(o, i) in objectOrderStanding" :key="o">
                <td v-if="i === 0" :rowspan="4" :colspan="1">
                  {{ $t("TOTAL") }}
                </td>
                <td v-if="i === 4" :rowspan="4" :colspan="1">
                  {{ $t("AWAY") }}
                </td>
                <td v-if="i === 8" :rowspan="4" :colspan="1">
                  {{ $t("LAST_6") }}
                </td>
                <td :colspan="2">{{ $t(standingLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width:
                          i === 2 ||
                          i === 3 ||
                          i === 6 ||
                          i === 7 ||
                          i === 10 ||
                          i === 11
                            ? (Number(result.awayTeamStat[o]).toFixed() / 5) *
                                100 +
                              '%'
                            : Number(result.awayTeamStat[o]).toFixed() + '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">{{ Number(result.awayTeamStat[o]) }}</td>
              </tr>
              <tr>
                <td
                  class="matchList-content_separator matchList-content_table-title"
                  :colspan="9"
                >
                  {{ `${$t("AWAY")} ${$t("W")} ${$t("GOALS")}` }}
                </td>
              </tr>
              <tr v-for="(o, i) in objectOrderWinGoal" :key="o">
                <td :colspan="3">{{ $t(winGoalLabel[i]) }}</td>
                <td :colspan="3">
                  <div class="percent-wrapper">
                    <div
                      class="percent-rate"
                      :style="{
                        width:
                          Number(result.awayTeamStat[`${o}Rate`]).toFixed() +
                          '%',
                      }"
                    ></div>
                  </div>
                </td>
                <td :colspan="3">
                  {{
                    `${Number(
                      result.awayTeamStat[o + "Rate"]
                    ).toFixed()}% [${Number(result.awayTeamStat[o])}${$t(
                      "SHORT_MATCH"
                    )}]`
                  }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </template>
  </section>

  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.currentSeasonStat"></GeneralContentSkeleton> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';

export default {
  components: {
    GeneralContentSkeleton,
  },
  props: ["matchDetailScore"],
  data() {
    return {
      isNoData: false,
      result: "",
      teamSelected: "homeTeam",
      teamData: "",
      objectOrderWinGoal: [
        "win2Goal",
        "win1Goal",
        "drawGoal",
        "lose1Goal",
        "lose2Goal",
      ],
      objectOrderTotal: [
        "totalWin",
        "totalDraw",
        "totalLose",
        "standingWin",
        "standingDraw",
        "standingLose",
      ],
      objectOrderStanding: [
        "totalGoal",
        "totalGoalLose",
        "avgGoal",
        "avgGoalLose",
        "totalStandingGoal",
        "totalStandingGoalLose",
        "avgStandingGoal",
        "avgStandingGoalLose",
        "lastSixTotalGoal",
        "lastSixTotalGoalLose",
        "lastSixAvgGoal",
        "lastSixAvgGoalLose",
      ],
      totalLabel: ["W", "DRAW", "L", "W", "DRAW", "L"],
      standingLabel: [
        "TOTAL_GS",
        "TOTAL_GC",
        "AVG_GS",
        "AVG_GC",
        "TOTAL_GS",
        "TOTAL_GC",
        "AVG_GS",
        "AVG_GC",
        "TOTAL_GS",
        "TOTAL_GC",
        "AVG_GS",
        "AVG_GC",
      ],
      winGoalLabel: [
        "WIN_GOAL_2",
        "WIN_GOAL_1",
        "DRAW",
        "LOSS_GOAL_1",
        "LOSS_GOAL_2",
      ],
      homeTeam: "",
      awayTeam: "",
      isSkeletonLoading: {
        currentSeasonStat: false,
      },
    };
  },
  mounted() {
    this.getCurrentSeasonStatData();
  },
  watch: {
    result: {
      handler(newVal) {
        this.homeTeam = {
          team: "homeTeam",
          name: newVal.homeTeamStat.teamName,
        };
        this.awayTeam = {
          team: "awayTeam",
          name: newVal.awayTeamStat.teamName,
        };
      },
    },
    manualRefreshMatchDetail: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getCurrentSeasonStatData();
        }
      }
    },
  },
  computed: {
    ...mapGetters(["isMobile", "manualRefreshMatchDetail"]),
  },
  methods: {
    ...mapActions(["getCurrentSeasonStat"]),
    init() {
      this.getCurrentSeasonStatData();
    },
    async getCurrentSeasonStatData() {
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.currentSeasonStat = true;
      }
      const result = await this.getCurrentSeasonStat({
        matchId: this.$route.params.matchId,
      });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.currentSeasonStat = false;
        this.verifyIfAllAPICallsDone();
      }

      result.result ? (this.result = result.result) : (this.isNoData = true);

      this.teamData = this.result.homeTeamStat;
    },
    selectTeam(id) {
      this.teamSelected = id;
      id === "homeTeam"
        ? (this.teamData = this.result.homeTeamStat)
        : (this.teamData = this.result.awayTeamStat);
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.currentSeasonStat) {
        this.$emit("apiCallsDone");
      }
    },
  },
};
</script>

<style scoped>
.matchList-content_table {
  table-layout: fixed;
  text-align: center;
}
.matchList-content_table td {
  padding: 0.375rem 0.75rem;
}
.matchList-content_separator {
  width: 100px;
}
tr {
  border-top: 0.5px solid #4b616c;
  border-bottom: 0.5px solid #4b616c;
}
.matchtips-content td {
  text-align: left;
  padding: 1rem;
}

.percent-wrapper {
  width: 5rem;
  height: 0.625rem;
  background: #35454d;
  margin: 0 auto;
  position: relative;
}
.percent-rate {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #6f8c9b;
}

@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.25rem 0;
  }
  /* .matchList-content_table th,
  .matchList-content_table td {
    border: unset;
  } */
  tr {
    border: 0.75px solid #4b616c;
  }
  .matchList-content_table .matchList-content_separator {
    padding: 0.75rem 0;
  }
  .matchtips-content td {
    text-align: left;
    padding: 0.75rem 1.25rem;
  }
}
</style>
