<template>
  <div class="matchList-content_overlay">
    <div class="matchList-content_wrapper">
      <table
        class="matchList-content_table table-full"
        v-for="(res, i) in theResults.slice(0, lengthSelected)"
        :key="res.matchId"
      >
        <thead>
          <tr>
            <th
              class="matchList-content_table-title"
              :colspan="subLabel.length"
            >
              {{ res.homeTeamName + " VS " + res.awayTeamName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="results.matchList">
            <tr>
              <td class="matchList-content_separator" :colspan="2">
                {{ $t(label[0].name) }}
              </td>
              <td :colspan="4">{{ theResults[i].leagueName }}</td>
            </tr>
            <tr>
              <td class="matchList-content_separator" :colspan="2">
                {{ $t(label[1].name) }}
              </td>
              <td :colspan="4">
                {{ matchDate(theResults[i].matchTime) }}
              </td>
            </tr>
            <tr>
              <td class="matchList-content_separator" :colspan="2">
                {{ $t(label[2].name) }}
              </td>
              <td :colspan="4">{{ score[i] }}</td>
            </tr>
            <tr>
              <td class="matchList-content_separator" :colspan="2">
                {{ $t(label[3].name) }}
              </td>
              <td :colspan="4">{{ corner[i] }}</td>
            </tr>
            <tr>
              <td class="matchList-content_separator" :colspan="3">
                {{
                  (handicapOptions.company
                    ? handicapOptions.company.name
                    : $t("NO_DATA")) +
                    "\xa0\xa0\xa0" +
                    (handicapOptions.duration
                      ? $t(handicapOptions.duration.name)
                      : $t("NO_DATA"))
                }}
              </td>
              <td class="matchList-content_separator" :colspan="3">
                {{
                  (oddsOptions.company
                    ? oddsOptions.company.name
                    : $t("NO_DATA")) +
                    "\xa0\xa0\xa0" +
                    (oddsOptions.duration
                      ? $t(oddsOptions.duration.name)
                      : $t("NO_DATA"))
                }}
              </td>
            </tr>
            <tr>
              <td v-for="sl in subLabel.slice(0, 6)" :key="sl">
                {{ $t(sl) }}
              </td>
            </tr>
            <tr>
              <template v-if="handicapOptions.result">
                <td>
                  {{
                    nullCheck(
                      handicapOptions.result.flat()[i][
                        handicapOptions.duration.state +
                          matchOptions.duration.state +
                          "Odds1"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      handicapOptions.result.flat()[i][
                        handicapOptions.duration.state +
                          matchOptions.duration.state +
                          "Odds2"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      handicapOptions.result.flat()[i][
                        handicapOptions.duration.state +
                          matchOptions.duration.state +
                          "Odds3"
                      ]
                    )
                  }}
                </td>
              </template>
              <template v-if="oddsOptions.result">
                <td>
                  {{
                    nullCheck(
                      oddsOptions.result.flat()[i][
                        oddsOptions.duration.state +
                          matchOptions.duration.state +
                          "Odds1"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      oddsOptions.result.flat()[i][
                        oddsOptions.duration.state +
                          matchOptions.duration.state +
                          "Odds2"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      oddsOptions.result.flat()[i][
                        oddsOptions.duration.state +
                          matchOptions.duration.state +
                          "Odds3"
                      ]
                    )
                  }}
                </td>
              </template>
            </tr>
            <tr>
              <td
                :colspan="subLabel.length"
                class="matchList-content_separator"
              >
                {{
                  matchOptions.duration
                    ? $t(matchOptions.duration.name)
                    : $t("NO_DATA")
                }}
              </td>
            </tr>
            <tr>
              <td
                :colspan="2"
                v-for="sl in subLabel.slice(6, subLabel.length)"
                :key="sl"
                class="matchList-content_separator"
              >
                {{ $t(sl) }}
              </td>
            </tr>
            <tr>
              <template v-if="handicapOptions.result">
                <td :colspan="2">
                  {{
                    matchResult(
                      handicapOptions.result.flat()[i][
                        matchOptions.duration.state.toLowerCase() + "WinFlag"
                      ]
                    )
                  }}
                </td>
                <td :colspan="2">
                  {{
                    winLose(
                      handicapOptions.result.flat()[i][
                        handicapOptions.duration.state +
                          matchOptions.duration.state +
                          "ConcedeFlag"
                      ]
                    )
                  }}
                </td>
                <td :colspan="2">
                  {{
                    bigSmall(
                      handicapOptions.result.flat()[i][
                        handicapOptions.duration.state +
                          matchOptions.duration.state +
                          "GoalFlag"
                      ]
                    )
                  }}
                </td>
              </template>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td class="no-stat" :colspan="label.length + subLabel.length">
                {{ $t("NO_DATA") }}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td :colspan="subLabel.length"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <Transition name="slide-in">
    <keep-alive>
      <router-view
        v-if="isMobile"
        :listOption="list"
        :lengthSelected="lengthSelected"
        @selectList="listLengthHandler"
        :oddsOptions="oddsOptions"
        :handicapOptions="handicapOptions"
        @selectCompany="companySelectHandler"
        :matchDuration="matchDuration"
        :matchPeriod="matchPeriod"
        :matchOptions="matchOptions"
        @selectDuration="durationSelectHandler"
        :leagueOption="results.leagueList"
        :leagueList="leagueList"
        @selectLeague="leagueListHandler"
        :isSame="isSame"
        @selectSide="isSameHandler"
      />
    </keep-alive>
  </Transition>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: ["side", "results"],
  data() {
    return {
      isNoData: false,
      isLoading: false,
      teamSelected: "",
      oddsMore: false,
      listOption: [10, 20, 30],
      lengthSelected: null,
      label: [
        { name: "EVENT", colspan: 0, rowspan: 2 },
        { name: "DATE", colspan: 0, rowspan: 2 },
        { name: "SCORE_HALFTIME", colspan: 0, rowspan: 2 },
        { name: "CORNER_KICK", colspan: 0, rowspan: 2 },
      ],
      subLabel: [
        "HOME",
        "HANDICAP",
        "AWAY",
        "HOME",
        "HANDICAP",
        "AWAY",
        "RESULTS",
        "HANDICAP",
        "B/S",
      ],
      company: [
        { name: "s", id: 1 },
        { name: "澳门", id: 1 },
        { name: "皇冠", id: 3 },
        { name: "立博", id: 4 },
        { name: "SNAI", id: 7 },
        { name: "BET365", id: 8 },
        { name: "威廉希尔", id: 9 },
        { name: "易胜博", id: 12 },
        { name: "韦德", id: 14 },
        { name: "明陞", id: 17 },
        { name: "Interwetten", id: 19 },
        { name: "10BET", id: 22 },
        { name: "金宝博", id: 23 },
        { name: "12BET", id: 24 },
        { name: "利记", id: 31 },
        { name: "盈禾", id: 35 },
        { name: "18BET", id: 42 },
        { name: "ManbetX/万博", id: 45 },
        { name: "平博", id: 47 },
        { name: "香港马会", id: 48 },
      ],
      matchDuration: [
        { state: "last", name: "FT" },
        { state: "first", name: "HT" },
      ],
      matchPeriod: [
        { state: "Full", name: "FULL_TIME" },
        { state: "Half", name: "HALF_TIME" },
      ],
      oddsOptions: {
        companyList: "",
        company: "",
        duration: "",
        companyShow: false,
        durationShow: false,
        result: "",
      },
      handicapOptions: {
        companyList: "",
        company: "",
        duration: "",
        companyShow: false,
        durationShow: false,
        result: "",
      },
      matchOptions: {
        matchShow: false,
        duration: "",
      },
      leagueList: [],
      filteredResults: "",
      isSame: false,
    };
  },
  mounted() {
    this.dataInit(this.results);
    console.log(this.$route);
  },
  computed: {
    ...mapGetters(["isMobile", "currentCountryObj"]),
    score() {
      return this.theResults.map(
        (m) =>
          (m.homeScore ? m.homeScore : "0") +
          "-" +
          (m.awayScore ? m.awayScore : "0") +
          " (" +
          (m.homeHalfScore ? m.homeHalfScore : "0") +
          "-" +
          (m.awayHalfScore ? m.awayHalfScore : "0") +
          ")"
      );
    },
    corner() {
      return this.theResults.map(
        (m) =>
          (m.homeCorner ? m.homeCorner : "0") +
          "-" +
          (m.awayCorner ? m.awayCorner : "0")
      );
    },
    theResults() {
      if (this.filteredResults.length !== 0) {
        return this.filteredResults;
      } else return this.results.matchList;
    },
    list() {
      for (let i = 0; i < this.listOption.length; i++) {
        if (this.results.matchList.length > this.listOption[i]) {
          return this.listOption.slice(0, i - 1);
        }
      }
    },
  },
  watch: {
    "oddsOptions.result"(newVal) {
      this["oddsOptions.result"] = newVal;
    },
    "handicapOptions.duration"(newVal) {
      this.handicapOptions.duration = newVal;
    },
    lengthSelected(newVal) {
      this.lengthSelected = newVal;
    },
    deep: true,
  },
  methods: {
    dataInit() {
      // matchList Length to show
      this.listLengthHandler(this.listOption[0]);
      // oddsInit
      this.oddsOptions.companyList = this.company.filter((o1) =>
        this.results.matchList[0].europeOddsList.some(
          (o2) => o1.id === o2.companyId
        )
      );
      this.oddsOptions.company = this.oddsOptions.companyList[0];
      this.oddsOptions.duration = this.matchDuration[0];
      this.oddsResultFilter(this.oddsOptions.company.id);
      // handicapInit
      this.handicapOptions.companyList = this.company.filter((o1) =>
        this.results.matchList[0].handicapList.some(
          (o2) => o1.id === o2.companyId
        )
      );
      this.handicapOptions.company = this.handicapOptions.companyList[0];
      this.handicapOptions.duration = this.matchDuration[0];
      this.handicapResultFilter(this.handicapOptions.company.id);
      // matchInit
      this.matchOptions.duration = this.matchPeriod[0];
    },
    // Handler
    dropdownHandler(data, table) {
      this[table][data] = !this[table][data];
    },
    companySelectHandler(data, table) {
      this[table].company = data;

      if (table === "oddsOptions") {
        this.oddsResultFilter(data.id);
      } else if (table === "handicapOptions") {
        this.handicapResultFilter(data.id);
      }
      this.$emit("selectCompany", data, table);
    },
    durationSelectHandler(data, table) {
      if (table) {
        this[table].duration = data;
      } else {
        this.oddsOptions.duration = data;
        this.handicapOptions.duration = data;
      }
      this.$emit("selectDuration", data, table);
    },
    oddsResultFilter(data) {
      this.oddsOptions.result = this.theResults.map((m) => {
        return m.europeOddsList.filter((odd) => odd.companyId === data);
      });
    },
    handicapResultFilter(data) {
      this.handicapOptions.result = this.theResults.map((m) => {
        return m.handicapList.filter((handicap) => handicap.companyId === data);
      });
    },
    matchDate(date) {
      return moment(date).format("YY-MM-DD");
    },
    selectTeam(id) {
      this.teamSelected = id;
    },
    listLengthHandler(data) {
      this.lengthSelected = data;
    },
    leagueListHandler(data) {
      if (!this.leagueList.includes(data)) {
        this.leagueList.push(data);
      } else this.leagueList = this.leagueList.filter((l) => l !== data);

      this.filterHandler();
    },
    isSameHandler() {
      this.isSame = !this.isSame;
      this.filterHandler();
    },
    filterHandler() {
      if (this.leagueList.length !== 0) {
        if (this.isSame) {
          this.filteredResults = this.results.matchList.filter((o1) =>
            this.leagueList.some((o2) => o1.leagueId === o2 && o1.isSame)
          );
        } else
          this.filteredResults = this.results.matchList.filter((o1) =>
            this.leagueList.some((o2) => o1.leagueId === o2)
          );
      } else {
        if (this.isSame) {
          this.filteredResults = this.results.matchList.filter(
            (o1) => o1.isSame
          );
        } else this.filteredResults = [];
      }

      this.oddsResultFilter(this.oddsOptions.company.id);
      this.handicapResultFilter(this.handicapOptions.company.id);
    },
    // Displaying Value if not null
    nullCheck(data) {
      return data ? data : "-";
    },
    winLose(data) {
      switch (data) {
        case -1:
          return this.$t("L");
        case 0:
          return this.$t("DRAW");
        case 1:
          return this.$t("W");
        default:
          return "-";
      }
    },
    bigSmall(data) {
      switch (data) {
        case -1:
          return this.$t("SMALL");
        case 0:
          return this.$t("MISS");
        case 1:
          return this.$t("BIG");
        default:
          return "-";
      }
    },
    matchResult(data) {
      switch (data) {
        case -1:
          return this.$t("L");
        case 0:
          return this.$t("DRAW");
        case 1:
          return this.$t("W");
        default:
          return "-";
      }
    },
  },
};
</script>

<style scoped>
.matchList-content_overlay {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 2;
  /* display: flex; */
  /* align-items: center; */
}
.matchList-content_wrapper {
  margin: 3rem auto 5rem auto;
  width: 95%;
  flex-direction: column;
}
.matchList-content_table {
  margin-top: 1rem;
}
.matchList-content_table td {
  padding: 0.375rem 0;
}

.slide-in-leave-active,
.slide-in-enter-active {
  transition: all 0.3s ease-in;
}
.slide-in-enter-from,
.slide-in-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(500px);
}
</style>
