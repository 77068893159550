<template>
<!--  v-show="!isSkeletonLoading.performanceRating" -->
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("PLAYER_PERFORMANCE") }} ({{ $t("PREV_GAME") }})
    </div>
    <div class="matchList-tab" v-if="isMobile">
      <button
        v-for="(res, idx) in results"
        :key="idx"
        @click="selectTeam(idx)"
        :class="{ 'tab-selected': idx === teamSelected }"
      >
        {{ res.name }}
      </button>
    </div>
    <div>
      <div class="matchList-content_wrapper">
        <!-- Mobile -->
        <template v-if="isMobile">
          <table
            class="matchList-content_table table-full"
            v-if="mobileResults"
          >
            <thead>
              <tr>
                <th
                  class="matchList-content_table-title"
                  :colspan="label.length"
                >
                  {{ mobileResults.name }}
                </th>
              </tr>
              <tr class="matchList-content_table-subtitle">
                <th v-for="(l, i) in label" :key="i">
                  {{ $t(l) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="mobileResults.players.length !== 0">
                <tr
                  v-for="(player, index) in mobileResults.players"
                  :key="index"
                >
                  <td>{{ player.number }}</td>
                  <td :style="{ textAlign: 'left' }">{{ player.name }}</td>
                  <td>{{ player.position }}</td>
                  <td>{{ player.rating }}</td>
                </tr>
                <tr>
                  <td colspan="3" class="matchList-content_performance-rating">
                    {{ $t("AVERAGE_SCORE") }}
                  </td>
                  <td>
                    {{ mobileResults.avgRating }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="no-stat" :colspan="label.length">
                    {{ $t("NO_DATA") }}
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </template>

        <!-- Desktop -->
        <template v-else>
          <table
            class="matchList-content_table table-half"
            v-for="(res, idx) in results"
            :key="idx"
          >
            <thead>
              <tr>
                <th class="matchList-content_table-title" colspan="4">
                  {{ res.name }}
                </th>
              </tr>
              <tr class="matchList-content_table-subtitle">
                <th
                  v-for="(l, i) in label"
                  :key="l"
                  :class="
                    i === 0
                      ? 'matchList-content_performance-number'
                      : i === 1
                      ? 'matchList-content_performance-name'
                      : null
                  "
                >
                  {{ $t(l) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="res.players.length !== 0">
                <tr v-for="(player, index) in res.players" :key="index">
                  <td>{{ player.number }}</td>
                  <td :style="{ textAlign: 'left' }">{{ player.name }}</td>
                  <td>{{ player.position }}</td>
                  <td>{{ player.rating }}</td>
                </tr>
                <tr>
                  <td colspan="3" class="matchList-content_performance-rating">
                    {{ $t("AVERAGE_SCORE") }}
                  </td>
                  <td>
                    {{ res.avgRating }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="no-stat" :colspan="label.length">
                    {{ $t("NO_DATA") }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </template>
      </div>
      <template v-if="results">
        <div v-if="isMobile">
          <div class="matchList-avgrating">
            {{
              teamSelected === "homeTeam"
                ? $t("AVGRATING10_HOME")
                : $t("AVGRATING10_AWAY")
            }}
            <template v-if="mobileResults.avgRatingList.length">
              <br />
              <span v-for="avg in mobileResults.avgRatingList" :key="avg">{{
                avg ? avg : "-"
              }}</span>
            </template>
            <template v-else><span>-</span></template>
          </div>
        </div>
        <div v-else>
          <div class="matchList-avgrating">
            {{ $t("AVGRATING10_HOME") }}
            <template v-if="results.homeTeam.avgRatingList.length">
              <span v-for="avg in results.homeTeam.avgRatingList" :key="avg">{{
                avg ? avg : "-"
              }}</span>
            </template>
            <template v-else><span>-</span></template>
          </div>
          <div class="matchList-avgrating">
            {{ $t("AVGRATING10_AWAY") }}
            <template v-if="results.awayTeam.avgRatingList.length">
              <span v-for="avg in results.awayTeam.avgRatingList" :key="avg">{{
                avg ? avg : "-"
              }}</span>
            </template>
            <template v-else><span>-</span></template>
          </div>
        </div>
      </template>
    </div>
  </section>

  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.performanceRating"></GeneralContentSkeleton> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';
export default {
  components: {
    GeneralContentSkeleton,
  },
  data() {
    return {
      label: ["NUMBER", "PLAYER", "POSITION", "SCORE"],
      teamSelected: "homeTeam",
      // isLoading: false,
      isNodata: false,
      results: null,
      mobileResults: null,
      matchId: "",
      isSkeletonLoading: {
        performanceRating: false,
      },
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.getPerformanceRating();
  },
  watch: {
    teamSelected(newVal) {
      this.mobileResults = this.results[newVal];
    },
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        this.getPerformanceRating();
      },
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.getPerformanceRating();
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "isMobile", 
      "currentLocale",
      "manualRefreshMatchDetail",
    ]),
  },
  methods: {
    ...mapActions(["getPerformance"]),
    selectTeam(id) {
      this.teamSelected = id;
    },
    async getPerformanceRating() {
      // this.isLoading = true;
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.performanceRating = true;
      }
      const result = await this.getPerformance({ matchId: this.matchId });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.performanceRating = false;
        this.verifyIfAllAPICallsDone();
      }
      // this.isLoading = false;

      this.dataValidation(result.result);
    },
    dataValidation(data) {
      if (!data.homeTeam && !data.awayTeam) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
        this.results = data;
        this.mobileResults = this.results[this.teamSelected];
      }
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.performanceRating) {
        this.$emit("apiCallsDone");
      }
    }
  },
};
</script>

<style scoped>
/* .matchList-container {
  width: 95%;
} */
.matchList-content_performance-rating {
  text-align: left;
  text-indent: 1.25rem;
}
tr {
  border-top: 0.5px solid #4b616c;
  border-bottom: 0.5px solid #4b616c;
}

.matchList-content_table td,
.matchList-content_table th {
  border: unset;
}
.matchList-avgrating {
  font-size: 0.75rem;
  line-height: 1rem;
  word-break: break-all;
}
.matchList-avgrating span {
  margin-right: 0.5rem;
}
@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.5rem 0;
  }
  tr {
    border-top: 0.75px solid #4b616c;
    border-bottom: 0.75px solid #4b616c;
  }
}
</style>
