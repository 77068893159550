<template>
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("GOAL_TIME") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        @click="selectTeam(homeTeam.team)"
        :class="{ 'tab-selected': homeTeam.team === teamSelected }"
      >
        {{ homeTeam.name }}
      </button>
      <button
        @click="selectTeam(awayTeam.team)"
        :class="{ 'tab-selected': awayTeam.team === teamSelected }"
      >
        {{ awayTeam.name }}
      </button>
    </div>

    <div class="matchList-content_wrapper">
      <!-- Mobile -->
      <template v-if="isMobile">
        <table class="matchList-content_table table-full">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th
                :colspan="positionLabel.length + 1"
                class="matchList-content_table-title"
              >
                {{
                  teamSelected === "homeTeam" ? homeTeam.name : awayTeam.name
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(l, i) in mobilePositionLabel"
                :key="i"
                :class="i === 0 ? 'matchList-content_separator' : null"
              >
                {{ $t(l) }}
              </td>
            </tr>
            <template v-if="homeShootTime || awayShootTime">
              <tr
                v-for="(o, index) in toMobileArray(
                  teamSelected === 'homeTeam'
                    ? splitHalf(homeShootTime, 'first')
                    : splitHalf(awayShootTime, 'first')
                )"
                :key="index"
              >
                <td class="matchList-content_separator">
                  {{ goalTimeStamp[index + 1] }}
                </td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
              <tr>
                <th
                  :colspan="positionLabel.length + 1"
                  class="matchList-content_separator padd-1"
                >
                  {{ $t("TIME_STATISTIC_GOAL") }}
                </th>
              </tr>
              <tr>
                <td
                  v-for="(l, i) in mobilePositionLabel"
                  :key="i"
                  :class="i === 0 ? 'matchList-content_separator' : null"
                >
                  {{ $t(l) }}
                </td>
              </tr>
              <tr
                v-for="(o, index) in toMobileArray(
                  teamSelected === 'homeTeam'
                    ? splitHalf(homeShootTime, 'second')
                    : splitHalf(awayShootTime, 'second')
                )"
                :key="index"
              >
                <td class="matchList-content_separator">
                  {{ goalTimeStamp[index + 1] }}
                </td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="mobilePositionLabel.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="positionLabel.length + 1"></td>
            </tr>
          </tfoot>
        </table>
      </template>

      <!-- Desktop -->
      <template v-else>
        <table class="matchList-content_table  table-half">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th :colspan="goalTimeStamp.length">{{ homeTeam.name }}</th>
            </tr>
            <tr>
              <th v-for="(time, i) in goalTimeStamp" :key="time">
                {{ i === 0 ? $t(time) : time }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="homeShootTime">
              <tr
                v-for="(o, index) in splitHalf(homeShootTime, 'first')"
                :key="index"
              >
                <td>{{ $t(positionLabel[index]) }}</td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
              <tr>
                <td
                  :colspan="goalTimeStamp.length"
                  class="matchList-content_separator"
                >
                  {{ $t("TIME_STATISTIC_GOAL") }}
                </td>
              </tr>
              <tr
                v-for="(o, index) in splitHalf(homeShootTime, 'second')"
                :key="index"
              >
                <td>{{ $t(positionLabel[index]) }}</td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="goalTimeStamp.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <table class="matchList-content_table  table-half">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th :colspan="goalTimeStamp.length">{{ awayTeam.name }}</th>
            </tr>
            <tr>
              <th v-for="time in goalTimeStamp" :key="time">
                {{ i === 0 ? $t(time) : time }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="awayShootTime">
              <tr
                v-for="(o, index) in splitHalf(awayShootTime, 'first')"
                :key="index"
              >
                <td>{{ $t(positionLabel[index]) }}</td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
              <tr>
                <td
                  :colspan="goalTimeStamp.length"
                  class="matchList-content_separator"
                >
                  {{ $t("TIME_STATISTIC_GOAL") }}
                </td>
              </tr>
              <tr
                v-for="(o, index) in splitHalf(awayShootTime, 'second')"
                :key="index"
              >
                <td>{{ $t(positionLabel[index]) }}</td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="goalTimeStamp.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["homeShootTime", "awayShootTime", "homeTeam", "awayTeam"],
  data() {
    return {
      positionLabel: ["TOTAL", "HOME", "AWAY"],
      mobilePositionLabel: ["TIME", "TOTAL", "HOME", "AWAY"],
      goalTimeStamp: [
        "TIME",
        "1-10",
        "11-20",
        "21-30",
        "31-40",
        "41-45",
        "46-50",
        "51-60",
        "61-70",
        "71-80",
        "81-90+",
      ],
      teamSelected: this.homeTeam.team,
    };
  },
  watch: {
    homeTeam(newVal) {
      this.teamSelected = newVal.team;
    },
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  methods: {
    selectTeam(id) {
      this.teamSelected = id;
    },
    toMobileArray(arr) {
      return eval(arr).reduce(
        (r, a, i) => (a.forEach((b, j) => ((r[j] = r[j] || [])[i] = b)), r),
        []
      );
    },
    splitHalf(arr, arrPos) {
      const firstHalf = arr.slice(0, Math.ceil(arr.length / 2));
      const secondHalf = arr.slice(-Math.ceil(arr.length / 2));
      if (arrPos === "first") {
        return firstHalf;
      } else if (arrPos === "second") {
        return secondHalf;
      }
    },
  },
};
</script>

<style scoped>
.matchList-content_table {
  table-layout: fixed;
}
th.padd-1 {
  padding: 0.75rem 0;
}

@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.25rem 0;
  }
}
</style>
