<template>
<!--  v-show="!isSkeletonLoading.leagueOdds" -->
  <section class="matchList-container">
    <div class="matchList-content-header">
      <div class="matchList-content_title">
        {{ oddsHeader }}
      </div>

      <router-link
        v-if="!isNoData && side === 'h2h' && isMobile"
        :to="{
          path: `${fullPath}/odds`,
          name: 'oddsResult',
          params: { side },
        }"
        class="matchList-tab_more"
        @click="oddsSeeMore()"
      >
        {{ $t("SEE_MORE") }}
        <div class="matchList-tab_more-arrow">
          <img
            src="../../../../static/images/icons/icon_arrow_right_white.png"
            alt=""
          />
        </div>
      </router-link>

      <div
        class="match-country__body-menu__tick"
        v-if="!isNoData && !isMobile && theResults"
      >
        <MatchListHeaderOption
          :listOption="list"
          :lengthSelected="lengthSelected"
          @lengthUpdated="listLengthHandler"
          :leagueOption="results.leagueList"
          :leagueList="thisOdds.leagueList"
          @leagueUpdated="leagueListHandler"
          :isSame="thisOdds.isSameLeague"
          @isSameUpdated="isSameHandler"
        />
      </div>
    </div>
    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile && side !== 'h2h'">
      <button class="tab-selected">
        {{
          side === "away"
            ? matchDetailScore.awayTeamName
            : matchDetailScore.homeTeamName
        }}
      </button>
      <router-link
        v-if="!isNoData"
        :to="{
          path: `${fullPath}/odds`,
          name: 'oddsResult',
          params: { side },
        }"
        class="matchList-tab_more"
        @click="oddsSeeMore()"
      >
        {{ $t("SEE_MORE") }}
        <div class="matchList-tab_more-arrow">
          <img
            src="../../../../static/images/icons/icon_arrow_right_white.png"
            alt=""
          />
        </div>
      </router-link>
    </div>

    <!-- Desktop -->
    <div class="matchList-content_wrapper" v-if="!isMobile">
      <table class="matchList-content_table table-full">
        <thead>
          <tr>
            <th
              :rowspan="l.rowspan"
              :colspan="l.colspan"
              v-for="l in label"
              :key="l"
            >
              {{ $t(l.name) }}
            </th>
            <th :colspan="3">
              <button
                class="matchList-button"
                @click="dropdownHandler('companyShow', 'handicapOptions')"
              >
                {{
                  thisOdds
                    ? $t(thisOdds.handicapOptions.company.name)
                    : $t("NO_DATA")
                }}
                <img src="../../../../static/images/match_arrow.png" />
                <div
                  class="dropdown-wrapper"
                  :class="{
                    selected: handicapOptions.companyShow && !isNoData,
                  }"
                >
                  <div class="ps ps--active-x ps--active-y">
                    <div class="dropdown-container__wrapper">
                      <div
                        class="dropdown-container"
                        v-for="o in handicapOptions.companyList"
                        :key="o.id"
                        @click="companySelectHandler(o, 'handicapOptions')"
                      >
                        <div class="dropdown-container__name">
                          <span>{{ $t(o.name) }}</span>
                        </div>
                        <div
                          class="dropdown-container__tick"
                          :class="{
                            selected:
                              thisOdds.handicapOptions.company.id === o.id,
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
              <button
                class="matchList-button"
                @click="dropdownHandler('durationShow', 'handicapOptions')"
              >
                {{
                  thisOdds
                    ? $t(thisOdds.handicapOptions.duration.name)
                    : $t("NO_DATA")
                }}
                <img src="../../../../static/images/match_arrow.png" />
                <div
                  class="dropdown-wrapper"
                  :class="{
                    selected: handicapOptions.durationShow && !isNoData,
                  }"
                >
                  <div class="ps ps--active-x ps--active-y" v-if="thisOdds">
                    <div class="dropdown-container__wrapper">
                      <div
                        class="dropdown-container"
                        v-for="o in matchDuration"
                        :key="o.state"
                        @click="durationSelectHandler(o, 'handicapOptions')"
                      >
                        <div class="dropdown-container__name">
                          <span>{{ $t(o.name) }}</span>
                        </div>
                        <div
                          class="dropdown-container__tick"
                          :class="{
                            selected:
                              thisOdds.handicapOptions.duration.state ===
                              o.state,
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </th>
            <th :colspan="3">
              <button
                class="matchList-button"
                @click="dropdownHandler('companyShow', 'oddsOptions')"
              >
                {{
                  thisOdds
                    ? $t(thisOdds.oddsOptions.company.name)
                    : $t("NO_DATA")
                }}
                <img src="../../../../static/images/match_arrow.png" />
                <div
                  class="dropdown-wrapper"
                  :class="{ selected: oddsOptions.companyShow && !isNoData }"
                >
                  <div class="ps ps--active-x ps--active-y">
                    <div class="dropdown-container__wrapper">
                      <div
                        class="dropdown-container"
                        v-for="o in oddsOptions.companyList"
                        :key="o.id"
                        @click="companySelectHandler(o, 'oddsOptions')"
                      >
                        <div class="dropdown-container__name">
                          <span>{{ $t(o.name) }}</span>
                        </div>
                        <div
                          class="dropdown-container__tick"
                          :class="{
                            selected: thisOdds.oddsOptions.company.id === o.id,
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
              <button
                class="matchList-button"
                @click="dropdownHandler('durationShow', 'oddsOptions')"
              >
                {{
                  thisOdds
                    ? $t(thisOdds.oddsOptions.duration.name)
                    : $t("NO_DATA")
                }}
                <img src="../../../../static/images/match_arrow.png" />
                <div
                  class="dropdown-wrapper"
                  :class="{ selected: oddsOptions.durationShow && !isNoData }"
                >
                  <div class="ps ps--active-x ps--active-y" v-if="thisOdds">
                    <div class="dropdown-container__wrapper">
                      <div
                        class="dropdown-container"
                        v-for="o in matchDuration"
                        :key="o.state"
                        @click="durationSelectHandler(o, 'oddsOptions')"
                      >
                        <div class="dropdown-container__name">
                          <span>{{ $t(o.name) }}</span>
                        </div>
                        <div
                          class="dropdown-container__tick"
                          :class="{
                            selected:
                              o.state === thisOdds.oddsOptions.duration.state,
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </th>
            <th :colspan="3">
              <button
                class="matchList-button"
                @click="dropdownHandler('matchShow', 'matchOptions')"
              >
                {{ thisOdds ? $t(thisOdds.matchOptions.name) : $t("NO_DATA") }}
                <img src="../../../../static/images/match_arrow.png" />
                <div
                  class="dropdown-wrapper"
                  :class="{ selected: matchOptions.matchShow && !isNoData }"
                >
                  <div class="ps ps--active-x ps--active-y" v-if="thisOdds">
                    <div class="dropdown-container__wrapper">
                      <div
                        class="dropdown-container"
                        v-for="o in matchPeriod"
                        :key="o.state"
                        @click="durationSelectHandler(o, 'matchOptions')"
                      >
                        <div class="dropdown-container__name">
                          <span>{{ $t(o.name) }}</span>
                        </div>
                        <div
                          class="dropdown-container__tick"
                          :class="{
                            selected: o.state === thisOdds.matchOptions.state,
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </th>
          </tr>
          <tr>
            <th v-for="sl in subLabel" :key="sl">
              {{ $t(sl) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="theResults">
            <tr
              v-for="(res, i) in theResults.slice(0, lengthSelected)"
              :key="res.matchId"
            >
              <td>{{ res.leagueName }}</td>
              <td>{{ matchDate(res.matchTime) }}</td>
              <td>{{ res.homeTeamName }}</td>
              <td>
                {{ score[i] }}
              </td>
              <td>
                {{ corner[i] }}
              </td>
              <td>{{ res.awayTeamName }}</td>
              <td>
                {{
                  nullCheck(
                    handicapOptions.result.flat()[i][
                      thisOdds.handicapOptions.duration.state +
                        thisOdds.matchOptions.state +
                        "Odds2"
                    ]
                  )
                }}
              </td>
              <td>
                {{
                  nullCheck(
                    handicapOptions.result.flat()[i][
                      thisOdds.handicapOptions.duration.state +
                        thisOdds.matchOptions.state +
                        "Odds1"
                    ]
                  )
                }}
              </td>

              <td>
                {{
                  nullCheck(
                    handicapOptions.result.flat()[i][
                      thisOdds.handicapOptions.duration.state +
                        thisOdds.matchOptions.state +
                        "Odds3"
                    ]
                  )
                }}
              </td>
              <td>
                {{
                  nullCheck(
                    oddsOptions.result.flat()[i][
                      thisOdds.oddsOptions.duration.state +
                        thisOdds.matchOptions.state +
                        "Odds1"
                    ]
                  )
                }}
              </td>
              <td>
                {{
                  nullCheck(
                    oddsOptions.result.flat()[i][
                      thisOdds.oddsOptions.duration.state +
                        thisOdds.matchOptions.state +
                        "Odds2"
                    ]
                  )
                }}
              </td>
              <td>
                {{
                  nullCheck(
                    oddsOptions.result.flat()[i][
                      thisOdds.oddsOptions.duration.state +
                        thisOdds.matchOptions.state +
                        "Odds3"
                    ]
                  )
                }}
              </td>
              <td>
                {{
                  matchResult(
                    handicapOptions.result.flat()[i][
                      thisOdds.matchOptions.state.toLowerCase() + "WinFlag"
                    ]
                  )
                }}
              </td>
              <td>
                {{
                  winLose(
                    handicapOptions.result.flat()[i][
                      thisOdds.handicapOptions.duration.state +
                        thisOdds.matchOptions.state +
                        "ConcedeFlag"
                    ]
                  )
                }}
              </td>
              <td>
                {{
                  bigSmall(
                    handicapOptions.result.flat()[i][
                      thisOdds.handicapOptions.duration.state +
                        thisOdds.matchOptions.state +
                        "GoalFlag"
                    ]
                  )
                }}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td class="no-stat" :colspan="label.length + subLabel.length">
                {{ $t("NO_DATA") }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <!-- Mobile -->
    <div class="matchList-content_wrapper" v-else>
      <table class="matchList-content_table table-full">
        <thead>
          <tr>
            <th
              class="matchList-content_table-title"
              :colspan="subLabel.length"
            >
              {{ oddsTitle }}
            </th>
          </tr>
        </thead>
        <tbody>
          <keep-alive>
            <template v-if="theResults">
              <tr>
                <td class="matchList-content_separator" :colspan="2">
                  {{ $t(label[0].name) }}
                </td>
                <td :colspan="4">{{ theResults[0].leagueName }}</td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="2">
                  {{ $t(label[1].name) }}
                </td>
                <td :colspan="4">
                  {{ matchDate(theResults[0].matchTime) }}
                </td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="2">
                  {{ $t(label[3].name) }}
                </td>
                <td :colspan="4">{{ score[0] }}</td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="2">
                  {{ $t(label[4].name) }}
                </td>
                <td :colspan="4">{{ corner[0] }}</td>
              </tr>
              <tr>
                <td class="matchList-content_separator" :colspan="3">
                  {{
                    (thisOdds
                      ? $t(thisOdds.handicapOptions.company.name)
                      : $t("NO_DATA")) +
                      "\xa0\xa0\xa0" +
                      (thisOdds
                        ? $t(thisOdds.handicapOptions.duration.name)
                        : $t("NO_DATA"))
                  }}
                </td>
                <td class="matchList-content_separator" :colspan="3">
                  {{
                    (thisOdds
                      ? $t(thisOdds.oddsOptions.company.name)
                      : $t("NO_DATA")) +
                      "\xa0\xa0\xa0" +
                      (thisOdds
                        ? $t(thisOdds.oddsOptions.duration.name)
                        : $t("NO_DATA"))
                  }}
                </td>
              </tr>
              <tr>
                <td v-for="sl in subLabel.slice(0, 6)" :key="sl">
                  {{ $t(sl) }}
                </td>
              </tr>
              <tr>
                <td>
                  {{
                    nullCheck(
                      handicapOptions.result.flat()[0][
                        thisOdds.handicapOptions.duration.state +
                          thisOdds.matchOptions.state +
                          "Odds2"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      handicapOptions.result.flat()[0][
                        thisOdds.handicapOptions.duration.state +
                          thisOdds.matchOptions.state +
                          "Odds1"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      handicapOptions.result.flat()[0][
                        thisOdds.handicapOptions.duration.state +
                          thisOdds.matchOptions.state +
                          "Odds3"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      oddsOptions.result.flat()[0][
                        thisOdds.oddsOptions.duration.state +
                          thisOdds.matchOptions.state +
                          "Odds1"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      oddsOptions.result.flat()[0][
                        thisOdds.oddsOptions.duration.state +
                          thisOdds.matchOptions.state +
                          "Odds2"
                      ]
                    )
                  }}
                </td>
                <td>
                  {{
                    nullCheck(
                      oddsOptions.result.flat()[0][
                        thisOdds.oddsOptions.duration.state +
                          thisOdds.matchOptions.state +
                          "Odds3"
                      ]
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td
                  :colspan="subLabel.length"
                  class="matchList-content_separator"
                >
                  {{
                    thisOdds ? $t(thisOdds.matchOptions.name) : $t("NO_DATA")
                  }}
                </td>
              </tr>
              <tr>
                <td
                  :colspan="2"
                  v-for="sl in subLabel.slice(6, subLabel.length)"
                  :key="sl"
                  class="matchList-content_separator"
                >
                  {{ $t(sl) }}
                </td>
              </tr>
              <tr>
                <td :colspan="2">
                  {{
                    matchResult(
                      handicapOptions.result.flat()[0][
                        thisOdds.matchOptions.state.toLowerCase() + "WinFlag"
                      ]
                    )
                  }}
                </td>
                <td :colspan="2">
                  {{
                    winLose(
                      handicapOptions.result.flat()[0][
                        thisOdds.handicapOptions.duration.state +
                          thisOdds.matchOptions.state +
                          "ConcedeFlag"
                      ]
                    )
                  }}
                </td>
                <td :colspan="2">
                  {{
                    bigSmall(
                      handicapOptions.result.flat()[0][
                        thisOdds.handicapOptions.duration.state +
                          thisOdds.matchOptions.state +
                          "GoalFlag"
                      ]
                    )
                  }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="label.length + subLabel.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </keep-alive>
        </tbody>
        <tfoot>
          <tr>
            <td :colspan="subLabel.length"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
  <!-- <Loading v-show="isLoading"></Loading>
  <div v-show="isNoData && !isLoading">
    {{ $t("NO_DATA") }}
  </div> -->
  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.leagueOdds"></GeneralContentSkeleton> -->
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/indicator/Loading.vue";
import MatchListHeaderOption from "@/components/layer/MatchListHeaderOption.vue";
import OddsResults from "@/components/mobile/matchListing/OddsResults.vue";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';

export default {
  components: { Loading, MatchListHeaderOption, OddsResults, GeneralContentSkeleton },
  props: {
    side: String,
    matchDetailScore: Object,
  },
  data() {
    return {
      isNoData: false,
      // isLoading: false,
      oddsMore: false,
      listOption: [10, 20, 30],
      lengthSelected: null,
      label: [
        { name: "EVENT", colspan: 0, rowspan: 2 },
        { name: "DATE", colspan: 0, rowspan: 2 },
        { name: "HOME", colspan: 0, rowspan: 2 },
        { name: "SCORE_HALFTIME", colspan: 0, rowspan: 2 },
        { name: "CORNER_KICK", colspan: 0, rowspan: 2 },
        { name: "AWAY", colspan: 0, rowspan: 2 },
      ],
      subLabel: [
        "HOME",
        "HANDICAP",
        "AWAY",
        "HOME",
        "DRAW",
        "AWAY",
        "RESULTS",
        "HANDICAP",
        "B/S",
      ],
      matchDuration: [
        { state: "last", name: "FT" },
        { state: "first", name: "HT" },
      ],
      matchPeriod: [
        { state: "Full", name: "FULL_TIME" },
        { state: "Half", name: "HALF_TIME" },
      ],
      oddsOptions: {
        companyList: "",
        company: "",
        duration: "",
        companyShow: false,
        durationShow: false,
        result: "",
      },
      handicapOptions: {
        companyList: "",
        company: "",
        duration: "",
        companyShow: false,
        durationShow: false,
        result: "",
      },
      matchOptions: {
        matchShow: false,
        duration: "",
      },
      results: "",
      filteredResults: "",
      fullPath: "",
      matchId: "",
      thisOdds: "",
      isSkeletonLoading: {
        leagueOdds: false,
      },
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.init();
    this.fullPath = this.$route.fullPath;
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.init();
      }
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.init();
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "isOddsMore",
      "homeOdds",
      "awayOdds",
      "h2hOdds",
      "companyGroup",
      "manualRefreshMatchDetail",
      "currentCountryObj"
    ]),
    score() {
      return this.theResults.map(
        (m) =>
          (m.homeScore ? m.homeScore : "0") +
          "-" +
          (m.awayScore ? m.awayScore : "0") +
          " (" +
          (m.homeHalfScore ? m.homeHalfScore : "0") +
          "-" +
          (m.awayHalfScore ? m.awayHalfScore : "0") +
          ")"
      );
    },
    corner() {
      return this.theResults.map(
        (m) =>
          (m.homeCorner ? m.homeCorner : "0") +
          "-" +
          (m.awayCorner ? m.awayCorner : "0")
      );
    },
    theResults() {
      if (this.filteredResults.length !== 0) {
        return this.filteredResults;
      } else return this.results.matchList;
    },
    oddsHeader() {
      if (this.side === "h2h") {
        return this.$t("MATCH_RECORD") + " - H2H";
      } else
        return !this.isMobile
          ? this.$t("RECENT_RECORD") +
              " - " +
              this.matchDetailScore[this.side + "TeamName"]
          : this.side === "away"
          ? ""
          : this.$t("RECENT_RECORD");
    },
    oddsTitle() {
      if (this.side === "h2h") {
        return (
          this.matchDetailScore.homeTeamName +
          " vs " +
          this.matchDetailScore.awayTeamName
        );
      } else if (this.side === "home")
        return (
          this.matchDetailScore[this.side + "TeamName"] +
          " vs " +
          this.matchDetailScore.awayTeamName
        );
      else {
        return (
          this.matchDetailScore[this.side + "TeamName"] +
          " vs " +
          this.matchDetailScore.homeTeamName
        );
      }
    },
    list() {
      for (let i = 0; i < this.listOption.length; i++) {
        if (this.results.matchList.length > this.listOption[i]) {
          return this.listOption.slice(0, i - 1);
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "getLeagueOdds",
      "isOddsMoreToggle",
      "oddsOptionChangeEvent",
      "isSameToggle",
      "oddsLeagueListHandler",
    ]),
    init() {
      this.getLeagueOddsData();
    },
    // Initialization
    async getLeagueOddsData() {
      // this.isLoading = true;
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.leagueOdds = true;
      }
      const result = await this.getLeagueOdds({
        side: this.side,
        matchId: { matchId: this.matchId },
      });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.leagueOdds = false;
        this.verifyIfAllAPICallsDone();
      }
      // this.isLoading = false;

      this.dataInit(result.result);
    },
    dataInit(data) {
      if (!data) {
        this.isNoData = true;
        return;
      } else {
        this.isNoData = false;
        this.results = data;
        // this.mobileResults = this.results[this.teamSelected];
      }

      if (this.side === "home") {
        this.thisOdds = this.homeOdds;
      } else if (this.side === "away") {
        this.thisOdds = this.awayOdds;
      } else this.thisOdds = this.h2hOdds;

      // matchList Length to show
      this.listLengthHandler(this.listOption[0]);

      // oddsInit
      for (let i = 0; i <this.companyGroup.length; i++) {
        if (this.companyGroup[i].id=== 1001) {
          this.companyGroup[i].name = this.currentCountryObj?.countryCode === "TH" ? "SBOBET" : "IBCbet"
        }
      }

      this.oddsOptions.companyList = this.companyGroup.filter((o1) =>
        this.results.matchList[0].europeOddsList.some(
          (o2) => o1.id === o2.companyId
        )
      );

      if (!this.thisOdds.matchOptions.name) {
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "oddsOptions",
          attr: "company",
          data: this.oddsOptions.companyList[0],
        });
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "oddsOptions",
          attr: "duration",
          data: this.matchDuration[0],
        });
      }

      // this.oddsOptions.company = this.oddsOptions.companyList[0];
      // this.oddsOptions.duration = this.matchDuration[0];
      this.oddsResultFilter(this.thisOdds.oddsOptions.company.id);

      // handicapInit
      this.handicapOptions.companyList = this.companyGroup.filter((o1) =>
        this.results.matchList[0].handicapList.some(
          (o2) => o1.id === o2.companyId
        )
      );
      if (!this.thisOdds.matchOptions.name) {
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "handicapOptions",
          attr: "company",
          data: this.handicapOptions.companyList[0],
        });
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "handicapOptions",
          attr: "duration",
          data: this.matchDuration[0],
        });
      }

      // this.handicapOptions.company = this.handicapOptions.companyList[0];
      // this.handicapOptions.duration = this.matchDuration[0];
      this.handicapResultFilter(this.thisOdds.handicapOptions.company.id);

      // matchInit
      if (!this.thisOdds.matchOptions.name) {
        this.oddsOptionChangeEvent({
          side: this.side,
          table: "matchOptions",
          data: this.matchPeriod[0],
        });
      }

      this.filterHandler();
      // this.matchOptions.duration = this.matchPeriod[0];
    },

    // Handler
    dropdownHandler(data, table) {
      this[table][data] = !this[table][data];
    },
    companySelectHandler(data, table) {
      this.oddsOptionChangeEvent({
        side: this.side,
        table: table,
        attr: "company",
        data: data,
      });

      // this[table].company = data;
      if (table === "oddsOptions") {
        this.oddsResultFilter(data.id);
      } else if (table === "handicapOptions") {
        this.handicapResultFilter(data.id);
      }
    },
    durationSelectHandler(data, table) {
      this.oddsOptionChangeEvent({
        side: this.side,
        table: table,
        attr: table === "matchOptions" ? null : "duration",
        data: data,
      });
    },
    oddsResultFilter(data) {
      this.oddsOptions.result = this.theResults.map((m) => {
        return m.europeOddsList.filter((odd) => odd.companyId === data);
      });
    },
    handicapResultFilter(data) {
      this.handicapOptions.result = this.theResults.map((m) => {
        return m.handicapList.filter((handicap) => handicap.companyId === data);
      });
    },
    matchDate(date) {
      return moment(date).format("YY-MM-DD");
    },
    filterHandler() {
      if (this.thisOdds.leagueList.length !== 0) {
        if (this.thisOdds.isSameLeague) {
          this.filteredResults = this.results.matchList.filter((o1) =>
            this.thisOdds.leagueList.some(
              (o2) => o1.leagueId === o2 && o1.isSame
            )
          );
        } else
          this.filteredResults = this.results.matchList.filter((o1) =>
            this.thisOdds.leagueList.some((o2) => o1.leagueId === o2)
          );
      } else {
        if (this.thisOdds.isSameLeague) {
          this.filteredResults = this.results.matchList.filter(
            (o1) => o1.isSame
          );
        } else this.filteredResults = [];
      }

      this.oddsResultFilter(this.thisOdds.oddsOptions.company.id);
      this.handicapResultFilter(this.thisOdds.handicapOptions.company.id);
    },
    isSameHandler() {
      this.isSameToggle(this.side);
      this.filterHandler();
    },
    listLengthHandler(data) {
      this.lengthSelected = data;
    },
    leagueListHandler(data) {
      this.oddsLeagueListHandler({ side: this.side, data: data });
      this.filterHandler();
    },
    oddsSeeMore() {
      this.oddsMore = !this.oddsMore;
      this.isOddsMoreToggle();
    },

    // Displaying Value if not null
    nullCheck(data) {
      return data || data === 0 ? data : "-";
    },
    winLose(data) {
      switch (data) {
        case -1:
          return this.$t("L");
        case 0:
          return this.$t("DRAW");
        case 1:
          return this.$t("W");
        default:
          return "-";
      }
    },
    bigSmall(data) {
      switch (data) {
        case -1:
          return this.$t("SMALL");
        case 0:
          return this.$t("DRAW");
        case 1:
          return this.$t("BIG");
        default:
          return "-";
      }
    },
    matchResult(data) {
      switch (data) {
        case -1:
          return this.$t("L");
        case 0:
          return this.$t("DRAW");
        case 1:
          return this.$t("W");
        default:
          return "-";
      }
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.leagueOdds) {
        this.$emit("apiCallsDone");
      }
    },
  },
};
</script>

<style scoped>
.matchList-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.matchList-button {
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  background: none;
  border: 0;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  min-width: 40px;
}

.matchList-button img {
  margin-left: 0.25rem;
}
.matchList-content_table th {
  padding: 0.3rem 0;
}
.matchList-content_table th,
.matchList-content_table td {
  max-width: 130px;
  border: 1px solid #4b616c;
}
.matchList-content_table td {
  padding: 0.3rem 0;
}
.matchList-content_title {
  margin: 0.625rem 0 0rem 1rem;
}
.dropdown-wrapper {
  min-width: 8rem;
}
.slide-in-leave-active,
.slide-in-enter-active {
  transition: all 0.3s ease-in;
}
.slide-in-enter-from,
.slide-in-leave-to {
  transform: translateX(500px);
}

@media (max-width: 768px) {
  .matchList-tab {
    justify-content: space-between;
  }
  .matchList-tab_more {
    font-size: 0.625rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .matchList-tab_more-arrow {
    margin-left: 0.625rem;
    display: flex;
  }
  .matchList-content_title {
    margin: 0.625rem 0;
  }
}
</style>
