<template>
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("PREMATCH_REPORT") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile && result">
      <button
        @click="selectTeam('homeTeam')"
        :class="{ 'tab-selected': 'homeTeam' === teamSelected }"
      >
        {{ result.homeTeamTips.teamName }}
      </button>
      <button
        @click="selectTeam('awayTeam')"
        :class="{ 'tab-selected': 'awayTeam' === teamSelected }"
      >
        {{ result.awayTeamTips.teamName }}
      </button>
    </div>

    <template v-if="isMobile">
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-full">
          <thead>
            <tr>
              <th class="matchList-content_table-title">
                {{ teamData.teamName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="matchtips-content">
              <td v-if="teamData.tipsContent">
                <p v-for="(d, i) in teamData.tipsContent.split('\n')" :key="i">
                  {{ d ? d : `&nbsp;` }}
                </p>
              </td>
              <td :style="{ textAlign: 'center' }" v-else>
                {{ $t("NO_DATA") }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>

    <template v-else>
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-half" v-if="result">
          <thead>
            <tr>
              <th class="matchList-content_table-title">
                {{ result.homeTeamTips.teamName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="matchtips-content">
              <td v-if="result.homeTeamTips.tipsContent">
                <p
                  v-for="(d, i) in result.homeTeamTips.tipsContent.split('\n')"
                  :key="i"
                >
                  {{ d ? d : `&nbsp;` }}
                </p>
              </td>
              <td v-else :style="{ textAlign: 'center' }">
                {{ $t("NO_DATA") }}
              </td>
            </tr>
            <!-- <tr class="matchtips-content">
              <td>Disadvantages</td>
            </tr> -->
          </tbody>
        </table>

        <table class="matchList-content_table table-half" v-if="result">
          <thead>
            <tr>
              <th class="matchList-content_table-title">
                {{ result.awayTeamTips.teamName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="matchtips-content">
              <td v-if="result.awayTeamTips.tipsContent">
                <p
                  v-for="(d, i) in result.awayTeamTips.tipsContent.split('\n')"
                  :key="i"
                >
                  {{ d ? d : `&nbsp;` }}
                </p>
              </td>
              <td v-else :style="{ textAlign: 'center' }">
                {{ $t("NO_DATA") }}
              </td>
            </tr>
            <!-- <tr class="matchtips-content">
              <td>Disadvantages</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["matchDetailScore"],
  data() {
    return {
      isNoData: false,
      result: "",
      teamSelected: "homeTeam",
      teamData: "",
    };
  },
  watch: {
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        this.getPreMatchReportData();
      },
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        this.getPreMatchReportData();
      },
    }
  },
  mounted() {
    this.getPreMatchReportData();
  },
  computed: {
    ...mapGetters(["isMobile", "currentLocale", "manualRefreshMatchDetail"]),
  },
  methods: {
    ...mapActions(["getPreMatchReport"]),
    init() {
      this.getPreMatchReportData();
      // this.selectTeam();
    },
    async getPreMatchReportData() {
      const result = await this.getPreMatchReport({
        matchId: this.$route.params.matchId,
      });
      if (this.manualRefreshMatchDetail) {
        this.verifyIfAllAPICallsDone();
      }
      result.result ? (this.result = result.result) : (this.isNoData = true);
      this.teamData = this.result.homeTeamTips;
    },
    selectTeam(id) {
      this.teamSelected = id;
      id === "homeTeam"
        ? (this.teamData = this.result.homeTeamTips)
        : (this.teamData = this.result.awayTeamTips);
    },
    verifyIfAllAPICallsDone() {
      // if (!this.isSkeletonLoading.goalScoringStats ) {
        this.$emit("apiCallsDone");
      // }
    },
  },
};
</script>

<style scoped>
.matchList-content_table {
  table-layout: fixed;
}
th.padd-1 {
  padding: 0.75rem 0;
}

.matchList-content_table td {
  border: unset;
  padding: 0.375rem 0.75rem;
}
.matchList-content_table th {
  border: unset;
}
tr {
  border-top: 0.5px solid #4b616c;
  border-bottom: 0.5px solid #4b616c;
}
.matchtips-content td {
  text-align: left;
  padding: 1rem;
  vertical-align: top;
}

@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.25rem 0;
  }
  .matchList-content_table th,
  .matchList-content_table td {
    border: unset;
  }
  tr {
    border: 0.75px solid #4b616c;
  }
  .matchtips-content td {
    text-align: left;
    padding: 0.75rem 1.25rem;
  }
  .matchList-content_title {
    margin: 0.625rem 0;
  }
}
</style>
