<template>
<!--  v-show="!isSkeletonLoading.mediaPredict" -->
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("MEDIA_PREDICTION") }}
    </div>

    <template v-if="isMobile">
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-full">
          <thead>
            <tr>
              <th class="matchList-content_table-title">
                {{ result.authorName ? result.authorName : $t("NO_DATA") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="matchtips-content">
              <td v-if="result.content">
                <p v-for="(d, i) in result.content.split('\n')" :key="i">
                  {{ d ? d : `&nbsp;` }}
                </p>
              </td>
              <td v-else>
                {{ $t("NO_DATA") }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
    <template v-else>
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-full">
          <tbody>
            <tr>
              <td class="matchList-content_separator">
                {{ result.authorName ? result.authorName : $t("NO_DATA") }}
              </td>
              <td v-if="result.content">
                <p v-for="(d, i) in result.content.split('\n')" :key="i">
                  {{ d ? d : `&nbsp;` }}
                </p>
              </td>
              <td v-else>
                {{ $t("NO_DATA") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </section>

  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.mediaPredict"></GeneralContentSkeleton> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';

export default {
  components: {
    GeneralContentSkeleton,
  },
  props: ["matchDetailScore"],
  data() {
    return {
      isNoData: false,
      result: "",
      isSkeletonLoading: {
        mediaPredict: false,
      },
    };
  },
  mounted() {
    this.getMediaPredictData();
  },
  watch: {
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        this.getMediaPredictData();
      },
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.getMediaPredictData();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["isMobile", "currentLocale", "manualRefreshMatchDetail"]),
  },
  methods: {
    ...mapActions(["getMediaPredict"]),
    init() {
      this.getMediaPredictData();
    },
    async getMediaPredictData() {
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.mediaPredict = true;
      }
      const result = await this.getMediaPredict({
        matchId: this.$route.params.matchId,
      });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.mediaPredict = false;
        this.verifyIfAllAPICallsDone();
      }
      result.result ? (this.result = result.result) : (this.isNoData = true);
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.mediaPredict) {
        this.$emit("apiCallsDone");
      }
    },
  },
};
</script>

<style scoped>
.matchList-content_table {
  table-layout: fixed;
}
.matchList-content_table td {
  border: unset;
  padding: 0.375rem 0.75rem;
}
.matchList-content_separator {
  width: 100px;
}
.matchList-content_table th {
  border: unset;
}
tr {
  border-top: 0.5px solid #4b616c;
  border-bottom: 0.5px solid #4b616c;
}
.matchtips-content td {
  text-align: left;
  padding: 1rem;
}

@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.25rem 0;
  }
  .matchList-content_table th,
  .matchList-content_table td {
    border: unset;
  }
  tr {
    border: 0.75px solid #4b616c;
  }
  .matchtips-content td {
    text-align: left;
    padding: 0.75rem 1.25rem;
  }
}
</style>
