<template>
<!--  v-show="!isSkeletonLoading.nextFiveMatch" -->
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("NEXT_5") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        v-for="(res, idx) in results"
        :key="idx"
        @click="selectTeam(idx)"
        :class="{ 'tab-selected': idx === teamSelected }"
      >
        {{ res.name }}
      </button>
    </div>

    <div class="matchList-content_wrapper" v-if="!isNoData">
      <!-- Mobile -->
      <template v-if="isMobile">
        <table class="matchList-content_table table-full" v-if="mobileResults">
          <thead>
            <tr>
              <th class="matchList-content_table-title" :colspan="label.length">
                {{ mobileResults.name }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="l in label" :key="l">{{ $t(l) }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="mobileResults.matches.length !== 0">
              <tr v-for="(match, index) in mobileResults.matches" :key="index">
                <td>{{ matchDate(match.matchTime) }}</td>
                <td>{{ match.leagueEnShort }}</td>
                <td>{{ match.match }}</td>
                <td>{{ match.day + " " + $t("DAYS") }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="label.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="label.length"></td>
            </tr>
          </tfoot>
        </table>
      </template>

      <!-- Desktop -->
      <template v-else>
        <table
          class="matchList-content_table table-half"
          v-for="(res, idx) in results"
          :key="idx"
        >
          <thead>
            <tr>
              <th class="matchList-content_table-title" :colspan="label.length">
                {{ res.name }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="l in label" :key="l">{{ $t(l) }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="res.matches.length !== 0">
              <tr v-for="(match, index) in res.matches" :key="index">
                <td>{{ matchDate(match.matchTime) }}</td>
                <td>{{ match.leagueEnShort }}</td>
                <td>{{ match.match }}</td>
                <td>{{ match.day + " " + $t("DAYS") }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="label.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>
  </section>
  <!-- <Loading v-show="isLoading"></Loading>
  <div v-show="isNoData && !isLoading">
    {{ $t("NO_DATA") }}
  </div> -->

  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.nextFiveMatch"></GeneralContentSkeleton> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Loading from "@/components/indicator/Loading.vue";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';

export default {
  components: { Loading, GeneralContentSkeleton },
  data() {
    return {
      label: ["DATE", "EVENT", "AGAINST", "APART"],
      teamSelected: "homeTeam",
      matchId: "",
      isLoading: false,
      isNoData: false,
      results: null,
      mobileResults: null,
      isSkeletonLoading: {
        nextFiveMatch: false,
      },
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  computed: {
    ...mapGetters(["isMobile", "currentLocale", "manualRefreshMatchDetail"]),
  },
  watch: {
    teamSelected(newVal) {
      this.mobileResults = this.results[newVal];
    },
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        this.getMatchDetailNext5Data();
      },
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.getMatchDetailNext5Data();
        }
      },
    },
  },
  mounted() {
    this.getMatchDetailNext5Data();
  },
  methods: {
    ...mapActions(["getNextFiveMatch", "manualRefreshMatchDetail"]),

    async getMatchDetailNext5Data() {
      // this.isLoading = true;
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.nextFiveMatch = true;
      }
      const result = await this.getNextFiveMatch({ matchId: this.matchId });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.nextFiveMatch = false;
        this.verifyIfAllAPICallsDone();
      }
      // this.isLoading = false;

      this.dataValidation(result.result);
    },
    dataValidation(data) {
      if (!data.homeTeam && !data.awayTeam) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
        this.results = data;
        this.mobileResults = this.results[this.teamSelected];
      }
    },
    matchDate(date) {
      return moment(date).format("MM-DD");
    },
    selectTeam(id) {
      this.teamSelected = id;
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.nextFiveMatch) {
        this.$emit("apiCallsDone");
      }
    },
  },
};
</script>
