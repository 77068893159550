<template>
<!--  v-show="!isSkeletonLoading.lastNMatches" -->
  <section class="matchList-container">
    <div class="matchList-content-header">
      <div class="matchList-content_title">
        {{ $t("DATA_COMPARISON") }}
      </div>
      <MatchListDropdown
        v-if="roundList"
        :lists="roundList"
        :lengthSelected="round"
        @lengthHandler="updateLength"
      />
    </div>

    <div class="matchList-content_wrapper">
      <!-- Mobile -->
      <template v-if="isMobile && result">
        <table class="matchList-content_table table-full">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th class="matchList-content_table-title">
                {{ $t(headerLabel[0]) }}
              </th>
              <th class="matchList-content_table-title">
                {{ homeTeam.teamName }}
              </th>
              <th class="matchList-content_table-title">
                {{ awayTeam.teamName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(h, i) in headerLabel.slice(1, headerLabel.length)"
              :key="i"
            >
              <td class="matchList-content_separator">{{ $t(h) }}</td>
              <td>{{ checkNull(homeTeam[objectOrder[i]], i) }}</td>
              <td>{{ checkNull(awayTeam[objectOrder[i]], i) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="3"></td>
            </tr>
          </tfoot>
        </table>
      </template>

      <!-- Desktop -->
      <template v-else>
        <table class="matchList-content_table  table-full">
          <thead>
            <tr>
              <th v-for="(l, i) in headerLabel" :key="l">
                {{ $t(l) }} {{ i === 5 || i === 6 || i === 7 ? "%" : null }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="result">
              <tr>
                <td class="matchList-content_separator">
                  {{ homeTeam.teamName }}
                </td>
                <td v-for="(o, i) in objectOrder" :key="o">
                  {{ checkNull(homeTeam[objectOrder[i]], i) }}
                </td>
              </tr>
              <tr>
                <td class="matchList-content_separator">
                  {{ awayTeam.teamName }}
                </td>
                <td v-for="(o, i) in objectOrder" :key="o">
                  {{ checkNull(awayTeam[objectOrder[i]], i) }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="headerLabel.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>
  </section>
  
  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.lastNMatches"></GeneralContentSkeleton> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MatchListDropdown from "../../ui/MatchListDropdown.vue";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';

export default {
  data() {
    return {
      headerLabel: [
        "TEAM",
        "TOTAL_GOALS",
        "TOTAL_GOALS_CONCEDED",
        "GOAL_DIFF",
        "GOALS_PER_GAME",
        "W",
        "DRAW",
        "L",
        "SAME_SIDE_GOAL",
        "SAME_SIDE_CONCEDED",
        "SAME_SIDE_DIFF",
        "SAME_SIDE_AVG",
        "SAME_SIDE_WIN",
        "SAME_SIDE_DRAW",
        "SAME_SIDE_LOSE",
      ],
      objectOrder: [
        "totalGoal",
        "totalGoalLose",
        "totalNettGoal",
        "averageGoal",
        "winRate",
        "drawRate",
        "loseRate",
        "standingGoal",
        "standingGoalLose",
        "standingNettGoal",
        "standingAverageGoal",
        "standingWinRate",
        "standingDrawRate",
        "standingLoseRate",
      ],
      isNoData: false,
      result: "",
      devResult: "",
      round: 1,
      isSkeletonLoading: {
        lastNMatches: false,
      },
    };
  },
  mounted() {
    this.getLastNMatchesData();
  },
  watch: {
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.getLastNMatchesData();
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "manualRefreshMatchDetail",
    ]),
    homeTeam() {
      return this.result && this.result[this.round - 1].homeTeam;
    },
    awayTeam() {
      return this.result && this.result[this.round - 1].awayTeam;
    },
    roundList() {
      let round = [];
      for (let i = 1; i < this.result.length + 1; i++) {
        round.push(i);
      }
      return round;
    },
  },
  methods: {
    ...mapActions(["getLastNMatches"]),
    async getLastNMatchesData() {
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.lastNMatches = true;
      }
      const result = await this.getLastNMatches({
        matchId: this.$route.params.matchId,
      });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.lastNMatches = false;
        this.verifyIfAllAPICallsDone();
      }
      result.result
        ? (this.result = result.result.matches)
        : (this.isNoData = true);
    },
    checkNull(data, pos) {
      if (data || data === 0) {
        if (
          pos === 4 ||
          pos === 5 ||
          pos === 6 ||
          pos === 11 ||
          pos === 12 ||
          pos === 13
        ) {
          return data + "%";
        } else return data;
      } else return "-";
    },
    updateLength(data) {
      this.round = data;
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.lastNMatches) {
        this.$emit("apiCallsDone");
      }
    },
  },
  components: {
    MatchListDropdown,
    GeneralContentSkeleton,
  },
};
</script>

<style scoped>
.matchList-content_table {
  table-layout: fixed;
}
th.padd-1 {
  padding: 0.75rem 0;
}
.matchList-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.matchList-content_table td {
  border: unset;
  /* padding: 0.375rem 0.75rem; */
}
.matchList-content_table th {
  border: unset;
}
.matchList-content_title {
  margin: 0.625rem 0 0.625rem 1rem;
}
tr {
  border-top: 0.5px solid #4b616c;
  border-bottom: 0.5px solid #4b616c;
}

@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.25rem 0;
  }
  .matchList-content_table th,
  .matchList-content_table td {
    border: 1px solid #4b616c;
  }
  tr {
    border-top: 0.75px solid #4b616c;
    border-bottom: 0.75px solid #4b616c;
  }
  .matchList-content_title {
    margin: 0.625rem 0;
  }
}
</style>
