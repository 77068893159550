<template>
 <!-- v-show="!isSkeletonLoading.goalScoringStats" -->
  <div>
    <LeagueOdds
      :homeOdds="homeOdds"
      :awayOdds="awayOdds"
      :homeTeam="homeTeam"
      :awayTeam="awayTeam"
    />
    <PreMatchReport @apiCallsDone="preMatchReportAPIDone" />
    <GoalScoringDistribution
      :homeTotalGoalScore="homeTotalGoalScore"
      :awayTotalGoalScore="awayTotalGoalScore"
      :homeTeam="homeTeam"
      :awayTeam="awayTeam"
    />
    <GoalScoringHalfTime
      :homeHalfTime="homeHalfTime"
      :awayHalfTime="awayHalfTime"
      :homeTeam="homeTeam"
      :awayTeam="awayTeam"
    />
    <GoalScoringTime
      :homeShootTime="homeShootTime"
      :awayShootTime="awayShootTime"
      :homeTeam="homeTeam"
      :awayTeam="awayTeam"
    />
  </div>

  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.goalScoringStats"></GeneralContentSkeleton>
  <GeneralContentSkeleton v-show="isSkeletonLoading.goalScoringStats"></GeneralContentSkeleton> -->
</template>

<script>
import GoalScoringDistribution from "./goalScoringStat/GoalScoringDistribution.vue";
import GoalScoringHalfTime from "./goalScoringStat/GoalScoringHalfTime.vue";
import GoalScoringTime from "./goalScoringStat/GoalScoringTime.vue";
import LeagueOdds from "./goalScoringStat/LeagueOdds.vue";
import PreMatchReport from "./PreMatchReport.vue";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    GoalScoringDistribution,
    GoalScoringHalfTime,
    GoalScoringTime,
    LeagueOdds,
    PreMatchReport,
    GeneralContentSkeleton,
  },
  props: {
    matchDetailScore: Object,
  },
  data() {
    return {
      matchId: "",
      results: "",
      // isLoading: false,
      isNoData: false,
      homeTotalGoalScore: "",
      awayTotalGoalScore: "",
      homeHalfTime: "",
      awayHalfTime: "",
      homeShootTime: "",
      awayShootTime: "",
      homeTeam: "",
      awayTeam: "",
      homeOdds: "",
      awayOdds: "",
      isSkeletonLoading: {
        goalScoringStats: false,
      },
      isPreMatchReportAPIDone: false,
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.getGoalScoringStatsData();
  },
  watch: {
    matchDetailScore(newVal) {
      this.awayTeam = {
        team: "awayTeam",
        name: newVal.awayTeamName,
      };

      this.homeTeam = {
        team: "homeTeam",
        name: newVal.homeTeamName,
      };
    },
    manualRefreshMatchDetail(newVal, oldVal) {
      if (newVal) {
        this.getGoalScoringStatsData();
      }
    },
  },
  computed: {
    ...mapGetters(["manualRefreshMatchDetail"]),
  },
  methods: {
    ...mapActions(["getGoalScoringStats"]),
    async getGoalScoringStatsData() {
      // this.isLoading = true;
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.goalScoringStats = true;
      }
      const result = await this.getGoalScoringStats({ matchId: this.matchId });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.goalScoringStats = false;
        this.verifyIfAllAPICallsDone();
      }
      // this.isLoading = false;

      this.dataValidation(result.result);
    },
    dataValidation(data) {
      this.homeTeam = {
        team: "homeTeam",
        name: this.matchDetailScore.homeTeamName,
      };
      this.awayTeam = {
        team: "awayTeam",
        name: this.matchDetailScore.awayTeamName,
      };

      if (data.homeTotalGoalScore) {
        this.homeTotalGoalScore = eval(data.homeTotalGoalScore);
      }
      if (data.awayTotalGoalScore) {
        this.awayTotalGoalScore = eval(data.awayTotalGoalScore);
      }
      if (data.homeHalfTime) {
        this.homeHalfTime = eval(data.homeHalfTime);
      }
      if (data.awayHalfTime) {
        this.awayHalfTime = eval(data.awayHalfTime);
      }
      if (data.homeShootTime) {
        this.homeShootTime = eval(data.homeShootTime);
      }
      if (data.awayShootTime) {
        this.awayShootTime = eval(data.awayShootTime);
      }
      if (data.homeOdds) {
        this.homeOdds = eval(data.homeOdds);
      }
      if (data.awayOdds) {
        this.awayOdds = eval(data.awayOdds);
      }
    },
    preMatchReportAPIDone() {
      this.isPreMatchReportAPIDone = true;
      this.verifyIfAllAPICallsDone();
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.goalScoringStats && this.isPreMatchReportAPIDone) {
        this.$emit("apiCallsDone");
      }
    },
  },
};
</script>

<style scoped></style>
